<template>
  <div class="widgetContainer widgetContainer--center bg-white loginOtp">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="cancel" />
        <p class="title">
          2-Factor Authentication
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="user"
        :rules="rules"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item prop="otp" :label="$t('tfa_Title')">
          <MobileOtp v-model="user.otp" :autofocus="true" />
        </el-form-item>
        <p class="loginOtp__resend-text">
          <span class="loginOtp__resend-timer" v-show="timer > 0">00:{{ (''+this.timer).length === 1 ? ('0'+this.timer) : this.timer }}</span>
          <span class="loginOtp__resend-btn" @click="resendOtp" :class="{'is-disabled': timer > 0 }">{{ $t('tfa_Resend') }}</span>
        </p>
      </el-form>
      <div class="widgetContainer__footer--fixed border-0">
        <el-button
          data-testid="submit"
          type="primary"
          @click="submitForm()"
          :disabled="isFormInValid"
          class="el-button__brand brand">
          {{ $t('next') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import MobileOtp from '@/components/MobileOtp';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import loginMixins from '../mixins/login';
import { getBrandConfig, getBrandEnv } from '@/utils/env';
import {Auth} from '@/services/auth';

export default {
  name: 'Login',
  mixins: [loginMixins],
  components: {
    MobileOtp
  },
  data() {
    return {
      timer: 60,
      user: {
        otp: ''
      },
      rules: {
        otp: 'required'
      }
    };
  },
  computed: {
    ...mapGetters('user', ['getUserDetails']),
    isFormInValid() {
      return !this.user.otp || this.user.otp.length != 6;
    }
  },
  created() {
    this.timerStart();
    /** @type {import('@/modules/user/store/state').userState['userDetails']} */
    const user = this.getUserDetails;
    if (!user || !user.phone) {
      this.$router.push('/user/login');
    }
  },
  methods: {
    ...mapActions('user', ['verifyOtp', 'getPerson', 'login']),
    ...mapMutations('user', ['updateUserOtp', 'setUserDetails']),
    async submitForm() {
      if (this.isFormInValid) return;
      const user = this.getUserDetails;
      const predifinedCode = getBrandConfig('programCode') || getBrandEnv('programCode');
      if (user && user.phone) {
        const loader = this.showLoader();
        const data = { otp: this.user.otp };
        if(predifinedCode && user.programCodeRequired === true) {
          data.programCode = predifinedCode;
        }
        try {
          await this.verifyOtp(data);
          await this.getPerson();
          await this.loginSuccess();
        }catch (e) {
          this.apiErrorMessage(e);
          if (e.code === 'EC_Identity_ERROR') {
            this.user.otp = '';
          }
        } finally {
          loader.close();
        }
      }
    },
    /**
     * resend otp
     */
    resendOtp() {
      if (this.timer === 0) {
        /** @type {import('@/modules/user/store/state').userState['userDetails']} */
        const user = this.getUserDetails;
        if (user && user.phone) {
          const loader = this.showLoader();
          Auth.resendOtp(user)
            .then(
              /**@param {import('@/modules/user/store/state').userState} user */
              () => {
                this.lastResendTime = new Date();
                this.$notify({
                  title: 'Success',
                  message: this.$t('tfa_ResendSuccess'),
                  type: 'success'
                });
              }
            )
            .catch((e) => {
              this.apiErrorMessage(e);
            })
            .finally(() => {
              loader.close();
              this.timerStart();
            });
        }
      }
    },
    cancel() {
      this.setUserDetails({
        phone: null,
        idNumberLast4Required: null,
        otp: null
      })
      setTimeout(() => {
        this.$router.push('/user/login');
      }, 0);
    },
    timerStart() {
      this.timer = 60;
      let setTimeout = setInterval(() => {
        this.timer = this.timer - 1;
        if (this.timer === 0) {
          clearInterval(setTimeout)
        }
      }, 1000)
    }
  }
};
</script>

<style lang="scss">
.loginOtp {
  &__resend-text {
    display: flex;
    font-size: 14px;
  }
  &__resend-btn {
    color: var(--branding);
    cursor: pointer;
    text-decoration: underline;
    margin-left: auto;
    &.is-disabled {
      cursor: not-allowed;
      color: grey;
    }
  }
  &__resend-timer {
    color: var(--color-red);
  }
  .navigationHeader {
    min-height: 68px;
  }
}

</style>
